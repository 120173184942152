const reduxData = {
  dailyTasks: [],
  calendars: [],
  events: [],
  user: 'bob',
  loggedIn: false,
  config: {
    hiddenCalendars: []
  },
  myQCalendar: {}
};

window.reduxData = reduxData;

export default reduxData;